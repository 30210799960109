import React from 'react';
import NotFoundPage1 from "./NotFoundPage1/NotFoundPage1";
// import NotFoundPage2 from './NotFoundPage2';
// import NotFoundPage3 from './NotFoundPage3';

const RandomNotFoundPage = () => {
    const pages = [NotFoundPage1];
    const RandomPage = pages[Math.floor(Math.random() * pages.length)];

    return <RandomPage />;
};

export default RandomNotFoundPage;
