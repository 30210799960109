import React from "react";
import AppRouter from "./router/Router";
import "./App.css";

const App = () => {
	return (
		<div className="App">
			<AppRouter />
		</div>
	);
};

export default App;
