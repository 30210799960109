import React, { useState, useCallback, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import PaginaDetalhePage from "../components/PaginaDetalhePage";
import { fetchFluxoBySlug } from "../api/api";
import RandomNotFoundPage from "../pages/RandomNotFoundPage";
import StarryBackgroundCanvas from "../components/StarryBackgroundCanvas";
import ChangelogPage from "../pages/ChangelogPage";


const AppRouter = () => {
    const [fluxo, setFluxo] = useState(null);
    const starryCanvasRef = useRef(null);

    const loadFluxo = useCallback(async (slug) => {
        const data = await fetchFluxoBySlug(slug);
        setFluxo(data);
        localStorage.setItem(`fluxo_${slug}`, JSON.stringify(data));
    }, []);

    return (
        <Router>
            <StarryBackgroundCanvas ref={starryCanvasRef} />
            <Routes>
                <Route path="/" element={<HomePage loadFluxo={loadFluxo} starryCanvasRef={starryCanvasRef} />} />
                <Route path="/fluxo/:fluxoSlug/:paginaSlug" element={<PaginaDetalhePage fluxo={fluxo} loadFluxo={loadFluxo} setFluxo={setFluxo} starryCanvasRef={starryCanvasRef} />} />
                <Route path="/changelog" element={<ChangelogPage />} />
                <Route path="*" element={<RandomNotFoundPage />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
