import StarryBackgroundCanvas from "../../components/StarryBackgroundCanvas";
import "./NotFoundPage1.css";
// import "./NotFoundScript";

const NotFoundPage1 = () => {
    return (
      <div className="simple-not-found-page">
          <StarryBackgroundCanvas />
          <div className="not-found-text">Erro 404</div>
          <div className="not-found-subtext">Parece que não há nada aqui, ainda...</div>
      </div>
  );
};

export default NotFoundPage1;
