import React, { useState } from "react";
import "./Dica.css";

const Dica = ({ dica, piscando }) => {
    const [isBlinking, setIsBlinking] = useState(piscando);

    const handleClick = () => {
        setIsBlinking(false);
    };

    return (
        <div
            className={`pagina-dica ${isBlinking ? 'blink_me' : ''}`}
            onClick={handleClick}
            dangerouslySetInnerHTML={{ __html: `${dica}` }}
        />
    );
};

export default Dica;
