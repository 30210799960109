import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ titulo, isHomePage }) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (!isHomePage) {
            navigate(-1);
        }
    };

    const handleTriagemClick = () => {
        navigate("/");
    };

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <div className={`nav-icon ${isHomePage ? "disabled" : ""}`} onClick={handleBackClick}><img src="/static/images/voltar-icon.png" alt="voltar" /></div>
                <div className="nav-icon"><img src="/static/images/home-icon.png" alt="home" /></div>
                <div className="nav-icon" onClick={handleTriagemClick}><img src="/static/images/tres-barras-icon.png" alt="tres barras" /></div>
            </div>

            <div className="navbar-center">
                <span className="page-title">{ titulo }</span>
                <img src="/static/images/portal-mv-telecom-header.png" alt="Logo" className="logo" />
            </div>

            <div className="navbar-right">
                <div className="nav-icon"><img src="/static/images/config-icon.png" alt="configurações" /></div>
                <div className="nav-icon"><img src="/static/images/user-icon.png" alt="perfil" /></div>
            </div>
        </nav>
    )
};

export default Navbar;
