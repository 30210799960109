import React, { useEffect, useState } from "react";
import { fetchFluxoBySlug } from "../api/api";
import PaginaContent from "../components/PaginaContent";

const HomePage = ({ loadFluxo, starryCanvasRef }) => {
    const [fluxo, setFluxo] = useState(null);

    useEffect(() => {
        const getFluxo = async () => {
            const data = await fetchFluxoBySlug("home");
            setFluxo(data);
        };
        getFluxo();
    }, []);

    if (!fluxo) {
        return <div>Loading...</div>;
    }

    const pagina = fluxo.paginas[0];

    return (
        <div className="homepage">
            <PaginaContent loadFluxo={loadFluxo} pagina={pagina} isHomePage={true} starryCanvasRef={starryCanvasRef} />
        </div>
    );
};

export default HomePage;
