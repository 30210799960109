import axios from "axios";

const API_URL = "/api/";

export const fetchFluxoBySlug = async(slug) => {
    try {
        const response = await axios.get(`${API_URL}fluxos/slug/${slug}/`);
        return response.data;
    } catch (error) {
        console.error(`Erro aos buscar o fluxo ${slug}:`, error);
        return null;
    }
};

export const fetchPaginaBySlug = async(slug) => {
    try {
        const response = await axios.get(`${API_URL}paginas/slug/${slug}/`);
        return response.data;
    } catch (error) {
        console.error("Erro ao buscar a página:", error);
        return null;
    }
};

export const fetchChangelog = async() => {
    try {
        const response = await axios.get(`${API_URL}changelog/`);
        return response.data;
    } catch (error) {
        console.log("Erro ao buscar pelo changelog: ", error);
        return null;
    }
};
