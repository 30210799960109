import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BotaoFluxo from "./BotaoFluxo";

const Card = ({ titulo, corDeFundo, botoes, onButtonClick, triggerStarAnimation, isMediaPage }) => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [isMoreThanFive, setIsMoreThanFive] = useState(false);

    useEffect(() => {
        if (botoes.length > 5) {
            setIsMoreThanFive(true);
        } else {
            setIsMoreThanFive(false);
        }
    }, [botoes.length]);

    const botoesOrdenados = botoes.sort((a, b) => a.prioridade - b.prioridade);

    return (
        <div className={`card ${isMediaPage ? 'midia-card' : ''}`} style={{ backgroundColor: corDeFundo }}>
            <h2>{titulo}</h2>
            <div ref={containerRef} className={`buttons-container ${isMoreThanFive ? 'more-than-five' : ''} ${isMediaPage ? 'midia-buttons-container' : ''}`}>
                {botoesOrdenados.map((botao) => (
                    <BotaoFluxo
                        key={botao.id}
                        botao={botao}
                        onClick={triggerStarAnimation}
                    />
                ))}
            </div>
        </div>
    );
};

export default Card;
