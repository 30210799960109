import React, { useEffect, useRef, useImperativeHandle, forwardRef } from "react";

const StarryBackgroundCanvas = forwardRef((props, ref) => {
    const canvasRef = useRef(null);
    const stars = useRef([]);
    const velocity = useRef({ x: 0, y: 0, tx: 0, ty: 0, z: 0.0005 });
    const scale = useRef(1);

    useImperativeHandle(ref, () => ({
        changeVelocity: (newVelocity) => {
            velocity.current.tx = newVelocity.tx;
            velocity.current.ty = newVelocity.ty;
        }
    }));

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const STAR_COLOR = "#fff";
        const STAR_SIZE = 3;
        const STAR_MIN_SCALE = 0.2;
        const OVERFLOW_THRESHOLD = 50;
        const STAR_COUNT = (window.innerWidth + window.innerHeight) / 8;

        function resize() {
            scale.current = window.devicePixelRatio || 1;
            const width = window.innerWidth * scale.current;
            const height = window.innerHeight * scale.current;

            canvas.width = width;
            canvas.height = height;

            stars.current.forEach(placeStar);
        }

        function placeStar(star) {
            star.x = Math.random() * canvas.width;
            star.y = Math.random() * canvas.height;
        }

        function recycleStar(star) {
            let direction = "z";
            const vx = Math.abs(velocity.current.x);
            const vy = Math.abs(velocity.current.y);

            if (vx > 1 || vy > 1) {
                let axis = vx > vy ? (Math.random() < vx / (vx + vy) ? "h" : "v") : (Math.random() < vy / (vx + vy) ? "v" : "h");
                direction = axis === "h" ? (velocity.current.x > 0 ? "l" : "r") : (velocity.current.y > 0 ? "t" : "b");
            }

            star.z = STAR_MIN_SCALE + Math.random() * (1 - STAR_MIN_SCALE);

            if (direction === "z") {
                star.z = 0.1;
                star.x = Math.random() * canvas.width;
                star.y = Math.random() * canvas.height;
            } else if (direction === "l") {
                star.x = -OVERFLOW_THRESHOLD;
                star.y = canvas.height * Math.random();
            } else if (direction === "r") {
                star.x = canvas.width + OVERFLOW_THRESHOLD;
                star.y = canvas.height * Math.random();
            } else if (direction === "t") {
                star.x = canvas.width * Math.random();
                star.y = -OVERFLOW_THRESHOLD;
            } else if (direction === "b") {
                star.x = canvas.width * Math.random();
                star.y = canvas.height + OVERFLOW_THRESHOLD;
            }
        }

        function update() {
            velocity.current.tx *= 0.96;
            velocity.current.ty *= 0.96;

            velocity.current.x += (velocity.current.tx - velocity.current.x) * 0.008;
            velocity.current.y += (velocity.current.ty - velocity.current.y) * 0.008;

            stars.current.forEach(star => {
                star.x += velocity.current.x * star.z;
                star.y += velocity.current.y * star.z;

                star.x += (star.x - canvas.width / 2) * velocity.current.z * star.z;
                star.y += (star.y - canvas.height / 2) * velocity.current.z * star.z;
                star.z += velocity.current.z;

                if (
                    star.x < -OVERFLOW_THRESHOLD ||
                    star.x > canvas.width + OVERFLOW_THRESHOLD ||
                    star.y < -OVERFLOW_THRESHOLD ||
                    star.y > canvas.height + OVERFLOW_THRESHOLD
                ) {
                    recycleStar(star);
                }
            });
        }

        function render() {
            context.clearRect(0, 0, canvas.width, canvas.height);

            stars.current.forEach(star => {
                context.beginPath();
                context.lineCap = "round";
                context.lineWidth = STAR_SIZE * star.z * scale.current;
                context.globalAlpha = 0.5 + 0.5 * Math.random();
                context.strokeStyle = STAR_COLOR;

                context.moveTo(star.x, star.y);

                let tailX = velocity.current.x * 2;
                let tailY = velocity.current.y * 2;

                if (Math.abs(tailX) < 0.1) tailX = 0.5;
                if (Math.abs(tailY) < 0.1) tailY = 0.5;

                context.lineTo(star.x + tailX, star.y + tailY);

                context.stroke();
            });
        }

        function step() {
            update();
            render();
            requestAnimationFrame(step);
        }

        stars.current = [];
        for (let i = 0; i < STAR_COUNT; i++) {
            stars.current.push({ x: 0, y: 0, z: STAR_MIN_SCALE + Math.random() * (1 - STAR_MIN_SCALE) });
        }

        resize();
        step();

        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        };
    }, []);

    return <canvas ref={canvasRef}></canvas>;
});

export default StarryBackgroundCanvas;
